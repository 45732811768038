var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-breadcrumb" }, [
    _c(
      "ul",
      { staticClass: "flex flex-wrap items-center" },
      [
        _vm._l(_vm.$route.meta.breadcrumb.slice(0, -1), function (link, index) {
          return _c(
            "li",
            { key: index, staticClass: "inline-flex items-center" },
            [
              link.url
                ? _c(
                    "router-link",
                    { staticClass: "breadcrumb-text", attrs: { to: link.url } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(link.title)) +
                          "\n            "
                      ),
                    ]
                  )
                : _c(
                    "span",
                    { staticClass: "cursor-default breadcrumb-text" },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t(link.title) || link.title) +
                          "\n            "
                      ),
                    ]
                  ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "breadcrumb-separator mx-2 flex items-start" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: _vm.$vs.trl
                        ? "ChevronsLeftIcon"
                        : "ChevronsRightIcon",
                      svgClasses: "w-4 h-4",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        }),
        _vm._v(" "),
        _c("li", { staticClass: "inline-flex content-area__heading" }, [
          _vm.$route.meta.breadcrumb.slice(-1)[0].active
            ? _c("h2", { staticClass: "cursor-default" }, [
                _vm._v(
                  _vm._s(_vm.$t(_vm.$route.meta.breadcrumb.slice(-1)[0].title))
                ),
              ])
            : _vm._e(),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }